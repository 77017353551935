import * as List from 'list.js';

export const aanbodFilters = () => {
  const typeSelect = document.querySelector('select[name=type]');
  const priceSelect = document.querySelector('select[name=price]');
  const el = document.querySelector('.woningen');
  var options = {
    valueNames: ['location', 'price', 'type'],
  };

  let list = new List(el, options);

  const updateList = () => {
    let typeValue = typeSelect.value;
    let priceValue = priceSelect.value;

    list.filter((item) => {
      return (
        (item.values().type.toLowerCase() == typeValue || typeValue == 'all') && (item.values().price == priceValue || priceValue == 'all')
      );
    });
  };

  typeSelect.addEventListener('change', () => updateList());
  priceSelect.addEventListener('change', () => updateList());

  updateList();
};
