'use strict';
import Mapbox from './includes/mapbox';

import { aanbodFilters } from './includes/aanbod-filters';

document.addEventListener('DOMContentLoaded', () => {
  const mobileMenu = document.querySelector('ul.mobile-menu');
  const menuToggle = document.querySelector('.menu-toggle');

  menuToggle.addEventListener('click', () => {
    document.body.classList.toggle('fixed');
    mobileMenu.classList.toggle('is-visible');
  });

  headerBg();
  window.addEventListener('scroll', () => {
    headerBg();
  });

  if (document.querySelector('.aanbod-filters')) aanbodFilters();
});

require('fslightbox');

const headerBg = () => {
  if (window.scrollY > 50) {
    document.querySelector('header.header').classList.add('dark-bg');
  } else {
    document.querySelector('header.header').classList.remove('dark-bg');
  }
  new Mapbox();
};

document.addEventListener('DOMContentLoaded', () => {
  const objectUrl = window.location.href;
  const hiddenInput = document.querySelector('#input_1_6');
  hiddenInput.value = objectUrl;
});
